import React from "react";
import product1 from '../../asset/product1.jpg'
import product2 from '../../asset/product2.jpg'
import product3 from '../../asset/product3.jpg'

export default function Catalog() {
    return (
        <>
            <div className="catalog-container">
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <h2 className="catalog-sidebar">ประเภทสินค้า</h2>
                        <div className="catalog-menu">
                            <a className="catalog-menu-item" href="#">สินค้าทั้งหมด</a>
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="row">

                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-2">
                                <div className="card card-item" >
                                    <img src={product1} className="card-img-top" alt=""></img>
                                    <div className="card-body">
                                        <h5 className="card-title">สร้อยข้อมือ</h5>
                                        <p className="card-text">รหัส: C120005</p>
                                        <p className="card-text">น้ำหนัก: 1/2 สลึง</p>
                                        <a href="#" className="btn btn-primary">ดูสินค้า</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-2">
                                <div className="card card-item" >
                                    <img src={product2} className="card-img-top" alt=""></img>
                                    <div className="card-body">
                                        <h5 className="card-title">สร้อยคอ</h5>
                                        <p className="card-text">รหัส: C120006</p>
                                        <p className="card-text">น้ำหนัก: 1 สลึง</p>
                                        <a href="#" className="btn btn-primary">ดูสินค้า</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-2">
                                <div className="card card-item" >
                                    <img src={product3} className="card-img-top" alt=""></img>
                                    <div className="card-body">
                                        <h5 className="card-title">สร้อยข้อมือ</h5>
                                        <p className="card-text">รหัส: C120007</p>
                                        <p className="card-text">น้ำหนัก: 1/2 สลึง</p>
                                        <a href="#" className="btn btn-primary">ดูสินค้า</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}