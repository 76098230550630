import React from "react";

export default function Aboutus() {
  return (
    <>
      <h4>ห้างทองเเก้วเยาวราช : HANGTHONGKAEWYAOWARAT</h4>
      <p>= ห้างทองแก้วเยาวราช จ.บุรีรัมย์
        ทองแท่ง ทองรูปพรรณ รับซื้อทองคำราคาสูงสุด
        ผ่อน/ออมทอง ดอกเบี้ย 0% =</p>
    </>
  );
}
