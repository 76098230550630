import React from "react";

export default function Contactus() {
    return (
        <>
            <h4>ติดต่อเราได้ที่: </h4>
            <p><span>🏠 </span> 103 ถนนสุนทรเทพ ตำบลในเมือง อำเภอเมืองบุรีรัมย์ จ.บุรีรัมย์ 31000</p>
            <p><span>📍 </span>บุรีรัมย์ ประเทศไทย</p>
            <p><span>📞 </span>081 486 5569</p>
        </>
    );
}